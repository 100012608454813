<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h1>Impressum</h1>
      </v-card-title>
      <v-card-subtitle class="mt-1">
        Angaben gemäß § 5 TMG
      </v-card-subtitle>
      <v-card-text>
        <v-card color="primary">
          <v-card-title>STAG Dienstleistungsgesellschaft mbH</v-card-title>
          <v-card-text>
            <ul style="list-style: none">
              <li>Frohsinnstraße 9</li>
              <li>63739 Aschaffenburg</li>
              <li>Deutschland</li>
            </ul>
            <br/>
            <ul style="list-style: none">
              <li><b>E-Mail:</b> info@stagrecht.de</li>
              <li><b>Telefon:</b> +49 6021 3627988</li>
            </ul>
            <br/>
          </v-card-text>
        </v-card>
        <v-card class="mt-3" color="primary">
          <v-card-title>STAG Legal Rechtsanwaltsgesellschaft UG (haftungsbeschränkt)</v-card-title>
          <v-card-text>
            <ul style="list-style: none">
              <li>Sackstraße 2</li>
              <li>37115 Duderstadt</li>
              <li>Deutschland</li>
              <li>HRB Nr. 205964, AG Göttingen</li>
            </ul>
            <br/>
            <ul style="list-style: none">
              <li><b>E-Mail:</b> info@stag-legal.de</li>
              <li><b>Telefon:</b> +49 6021 3627988</li>
            </ul>
            <br/>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>

</style>
